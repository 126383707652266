import { Conversation, Feedback, historyList } from "../../api";
import { Action, AppState } from "./AppProvider";

// Define the reducer function
export const appStateReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case "TOGGLE_CHAT_HISTORY":
      return { ...state, isChatHistoryOpen: !state.isChatHistoryOpen };
    case "TOGGLE_SHARE":
      return { ...state, isShareOpen: !state.isShareOpen };
    case "SET_IS_SMALL_SCREEN":
      return { ...state, isSmallScreen: action.payload };
    case "EXTRACTED_ID":
      return { ...state, extractedId: action.payload };
    case "UPDATE_CURRENT_CHAT":
      return { ...state, currentChat: action.payload };
    case "UPDATE_CHAT_HISTORY_LOADING_STATE":
      return { ...state, chatHistoryLoadingState: action.payload };
    case "UPDATE_CHAT_HISTORY":
      if (!state.chatHistory || !state.currentChat) {
        return state;
      }
      let conversationIndex = state.chatHistory.findIndex(
        (conv) => conv.id === action.payload.id
      );
      if (conversationIndex !== -1) {
        let updatedChatHistory = [...state.chatHistory];
        updatedChatHistory[conversationIndex] = state.currentChat;
        updatedChatHistory.splice(conversationIndex, 1);

        // Insert the extracted value at the start of the array
        updatedChatHistory.unshift(state.currentChat);
        // updatedChatHistory = updatedChatHistory.sort((a, b) => {
        //   // Convert date strings to Date objects and compare their timestamps
        //   // Subtracting b's timestamp from a's to sort in descending order
        //   return new Date(b.date).getTime() - new Date(a.date).getTime();
        // });
        return {
          ...state,
          chatHistory: updatedChatHistory,
        };
      } else {
        return {
          ...state,
          chatHistory: [...state.chatHistory, action.payload],
        };
      }
    case "UPDATE_CHAT_TITLE":
      if (!state.chatHistory) {
        return { ...state, chatHistory: [] };
      }
      let updatedChats = state.chatHistory.map((chat) => {
        if (chat.id === action.payload.id) {
          if (state.currentChat?.id === action.payload.id) {
            state.currentChat.title = action.payload.title;
          }
          //TODO: make api call to save new title to DB
          return { ...chat, title: action.payload.title };
        }
        return chat;
      });
      return { ...state, chatHistory: updatedChats };
    case "DELETE_CHAT_ENTRY":
      if (!state.chatHistory) {
        return { ...state, chatHistory: [] };
      }
      let filteredChat = state.chatHistory.filter(
        (chat) => chat.id !== action.payload
      );
      state.currentChat = null;
      //TODO: make api call to delete conversation from DB
      return { ...state, chatHistory: filteredChat };
    case "DELETE_CHAT_HISTORY":
      //TODO: make api call to delete all conversations from DB
      return {
        ...state,
        chatHistory: [],
        filteredChatHistory: [],
        currentChat: null,
      };
    case "DELETE_CURRENT_CHAT_MESSAGES":
      //TODO: make api call to delete current conversation messages from DB
      if (!state.currentChat || !state.chatHistory) {
        return state;
      }
      const updatedCurrentChat = {
        ...state.currentChat,
        messages: [],
      };
      return {
        ...state,
        currentChat: updatedCurrentChat,
      };
    case "FETCH_CHAT_HISTORY":
      return { ...state, chatHistory: action.payload };
    case "SET_COSMOSDB_STATUS":
      return { ...state, isCosmosDBAvailable: action.payload };
    case "FETCH_FRONTEND_SETTINGS":
      return { ...state, frontendSettings: action.payload };
    case "SET_FEEDBACK_STATE":
      return {
        ...state,
        feedbackState: {
          ...state.feedbackState,
          [action.payload.answerId]: action.payload.feedback,
        },
      };
    case "SET_EDIT_CHAT":
      return {
        ...state,
        editedChatArray: [...state.editedChatArray, ...action.payload],
      };
    case "SET_IS_AUTHENTICATED":
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case "SET_AUTH_MODE":
      return {
        ...state,
        authMode: action.payload,
      };

    case "SET_ACTIVE_STACK":
      return {
        ...state,
        activeStack: action.payload,
      };
    case "SET_ACTIVE_WINDOW":
      return {
        ...state,
        activeWindow: action.payload,
      };
    default:
      return state;
  }
};
