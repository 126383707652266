import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  FormEvent,
} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { getUploadedDocument, selfUploadApi } from "../../../api";
import styles from "./Upload.module.css";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";
import { Loader, isValidUrl } from "../../CarbonAIChat/common/Common";
import { fileStatus, upload } from "../../../constants/constants";
import { Stack } from "@fluentui/react";

type FormData = {
  type: "file" | "url";
  url?: string;
  files?: File[];
  source?: { [fileName: string]: string };
  count?: string;
};

const Upload: React.FC = () => {
  const appStateContext = useContext(AppStateContext);
  const [formData, setFormData] = useState<FormData>({
    type: "url",
    url: "",
    files: [],
    source: {},
  });

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const validFilesList = useRef<string[]>([]);
  const invalidFilesList = useRef<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [urlError, setUrlError] = useState<string | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [modalMsg, setModalMsg] = useState<string>("");
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showInvalidFileModal, setShowInvalidFileModal] = useState(false);
  const [showModalConfirmUpload, setShowModalConfirmUpload] =
    useState<boolean>(false);

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    resetFormErrors();
    setFormData({
      ...formData,
      type: event.target.value as "file" | "url",
      url: "",
      files: [],
      source: {},
    });
  };

  const fetchData = () => {
    setFetching(true);
    getUploadedDocument()
      .then((response) => response.json())
      .then((jsonData) => {
        setTableData(jsonData);
        setFetching(false);

        if (jsonData.some((row: any) => row.status_code === 0)) {
          timeoutRef.current = setTimeout(fetchData, 30000);
        }
      })
      .catch((error) => {
        setTableData([]);
        console.error("Error fetching data", error);
        setFetching(false);
      });
  };

  useEffect(() => {
    fetchData();
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, []);

  const resetFormErrors = () => {
    setUrlError("");
    setFileError(null);
  };

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrlError("");
    setFormData({ ...formData, url: event.target.value.trim() });
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      invalidFilesList.current = [];
      validFilesList.current = [];
      formData.source = {};
      setFileError(null);

      const filesArray = Array.from(event.target.files);
      const totalSize =
        filesArray.reduce((acc, file) => acc + file.size, 0) / 1024; // Total size in KB , divide by 1024 to get content size

      setFormData({ ...formData, files: filesArray });
      if (totalSize > 15000) {
        // 15,000 KB limit
        setFileError(
          "Total content size of file/files exceeds the 15 MB limit."
        );
        return;
      }

      if (filesArray.length > 5) {
        setFileError("You can upload a maximum of 5 files.");
        return;
      }
    }
  };

  const handleSourceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fileName: string
  ) => {
    const newSource = {
      ...formData.source,
      [fileName]: event.target.value.trim(),
    };
    setFormData({ ...formData, source: newSource });
  };

  const uploadAPI = () => {
    setShowLoader(true);
    const payload = new FormData();
    payload.append("type", formData.type);
    const url = window.location.hostname;
    if (url === "uatchat.carbonai.eco") {
      payload.append("env", "UAT");
    } else if (url === "chat.carbonai.eco") {
      payload.append("env", "PROD");
    } else if (url === "devchat.carbonai.eco") {
      payload.append("env", "DEV");
    } else {
      payload.append("env", "LOCAL");
    }

    if (
      formData.type === "file" &&
      formData.files &&
      Array.isArray(formData.files)
    ) {
      payload.append("count", validFilesList.current.length.toString());
      formData.files.forEach((file: File, index: number) => {
        if (invalidFilesList.current.indexOf(file.name) === -1)
          payload.append(`files_${index}`, file);
      });
    }

    if (formData.type === "url" && formData.url) {
      payload.append("url", formData.url);
    }

    if (
      formData.type === "file" &&
      formData.source &&
      Object.keys(formData.source).length
    ) {
      payload.append("source", JSON.stringify(formData.source));
    }

    const handleSuccess = (files: string[]) => {
      if (formData.type === "url") {
        setModalMsg(upload.uploadSuccess);
        setShowLoader(false);
        setShowModal(true);
      } else {
        invalidFilesList.current = [...invalidFilesList.current, ...files];
        updateValidFilesList(files);
        setShowLoader(false);
        setShowInvalidFileModal(true);
      }
    };

    const handleFailure = (files: string[]) => {
      if (formData.type === "url") {
        setModalMsg(upload.upload404);
        setShowLoader(false);
        setShowModal(true);
      } else {
        invalidFilesList.current = [...invalidFilesList.current, ...files];
        updateValidFilesList(files);
        setShowLoader(false);
        setShowInvalidFileModal(true);
      }
    };

    const updateValidFilesList = (invalidFiles: string[]) => {
      validFilesList.current = validFilesList.current.filter(
        (file) => !invalidFiles.includes(file)
      );
    };

    const handleError = () => {
      setModalMsg(
        formData.type === "file" ? upload.uploadError : upload.upload404
      );
      setShowLoader(false);
      setShowModal(true);
    };

    selfUploadApi(payload)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.status_code === 200) {
            handleSuccess(data.invalid_files);
          } else if (data.status_code === 201) {
            handleFailure(data.invalid_files);
          } else {
            handleError();
          }
        } else {
          handleError();
        }
      })
      .catch(() => handleError());
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (fileError || urlError) {
      return;
    }
    setShowLoader(true);
    resetFormErrors();

    if (formData.type === "url" && formData.url) {
      if (!isValidUrl(formData.url.trim())) {
        setUrlError(upload.invalidUrl);
        setShowLoader(false);
        return;
      }
    } else if (formData.type === "file") {
      if (!formData.files || formData.files.length === 0) {
        setFileError("Please select files to upload.");
        setShowLoader(false);
        return;
      } else {
        formData.files.forEach((file) => {
          if (
            file.size === 0 ||
            ![
              "application/pdf",
              "text/plain",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ].includes(file.type)
          ) {
            invalidFilesList.current.push(file.name);
          } else {
            validFilesList.current.push(file.name);
          }
        });

        if (validFilesList.current.length === 0) {
          setShowLoader(false);
          setShowInvalidFileModal(true);
          return;
        }

        if (formData.source) {
          if (Object.keys(formData.source).length > 0) {
            let showMessage = false;
            Object.values(formData.source).some((source) => {
              if (source.trim().length === 0 || !isValidUrl(source.trim())) {
                showMessage = true;
                return true; // Stops iteration once a condition is met
              }
            });
            if (showMessage) {
              setShowLoader(false);
              setShowModalConfirmUpload(true);
              return;
            }
          } else {
            // Handle case where formData.source is an empty object
            setShowLoader(false);
            setShowModalConfirmUpload(true);
            return;
          }
        } else {
          // Handle case where formData.source is undefined
          setShowLoader(false);
          setShowModalConfirmUpload(true);
          return;
        }
      }
    }

    // Call upload API if all conditions are met
    uploadAPI();
  };

  return (
    <>
      <Stack
        className={
          appStateContext?.state.isSmallScreen
            ? `form-group ${styles.mainSmallScreen}`
            : `form-group ${styles.main}`
        }
      >
        <Stack.Item className={styles.instructionSection}>
          <div className={`${styles.instruction} borderAll`}>
            <b>How to Upload:</b>
            <br />
            <ul>
              <li>
                Choose a File or URL: You can upload your data by selecting a
                file from your device or entering a URL.
              </li>
            </ul>
            <br /> <b>Accepted File Types:</b>
            <br />
            <ul>
              <li> Text (.txt)</li>
              <li> PDF (.pdf)</li>
              <li> Word (.doc, .docx)</li>
            </ul>
            <br />
            <b> Notifications:</b>
            <br />
            <ul>
              <li>
                Total content size of all files should be less than equal to 15
                MB.
              </li>
              <li>
                You will receive a notification on the user interface once your
                file is successfully received.
              </li>
              <li>
                Further processing of the file will continue in the background.
              </li>
            </ul>
            <br /> Please ensure your files are of the supported types to
            facilitate smooth processing.
          </div>
        </Stack.Item>
        <Stack.Item className={styles.workSection}>
          <Stack>
            <div>
              <form
                ref={formRef}
                onSubmit={handleSubmit}
                className={`form-horizontal ${styles.form}`}
              >
                <div
                  className={
                    appStateContext?.state.isSmallScreen
                      ? `form-group ${styles.formRowSmallScreen}`
                      : `form-group ${styles.formRow}`
                  }
                >
                  <label className="control-label col-lg-1">Type</label>
                  <div className="col-lg-11">
                    <input
                      type="radio"
                      name="uploadType"
                      value="url"
                      checked={formData.type === "url"}
                      onChange={handleTypeChange}
                    />
                    URL
                    <input
                      type="radio"
                      name="uploadType"
                      value="file"
                      checked={formData.type === "file"}
                      onChange={handleTypeChange}
                    />
                    File
                  </div>
                </div>
                {formData.type === "url" ? (
                  <div
                    className={
                      appStateContext?.state.isSmallScreen
                        ? `form-group ${styles.formRowSmallScreen}`
                        : `form-group ${styles.formRow}`
                    }
                  >
                    <label className="control-label col-lg-1">URL</label>
                    <div className="col-lg-11">
                      <input
                        type="text"
                        className={styles.inputField}
                        value={formData.url}
                        onChange={handleUrlChange}
                        placeholder="Enter URL"
                      />
                      {urlError && (
                        <div className={styles.error}>{urlError}</div>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      className={
                        appStateContext?.state.isSmallScreen
                          ? `form-group ${styles.formRowSmallScreen}`
                          : `form-group ${styles.formRow}`
                      }
                    >
                      <label className="control-label col-lg-1">
                        File to upload
                      </label>
                      <div className="col-lg-11">
                        <input
                          title={
                            formData.files && formData.files.length === 1
                              ? formData.files[0].name
                              : `${formData.files?.length} files selected`
                          }
                          type="file"
                          accept=".doc,.docx,.pdf,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          className={`${styles.customfileinput} ${styles.inputField}`}
                          onChange={handleFileChange}
                          multiple
                        />
                        {fileError && (
                          <div className={styles.error}>
                            {formData.files && formData.files.length > 5
                              ? "You can upload a maximum of 5 files."
                              : fileError}
                          </div>
                        )}
                      </div>
                    </div>
                    {formData.files &&
                      Array.isArray(formData.files) &&
                      formData.files.length < 6 &&
                      (formData.files.length > 1 ? (
                        <div
                          className={
                            appStateContext?.state.isSmallScreen
                              ? `form-group ${styles.formRowSmallScreen} `
                              : `form-group ${styles.formRow}`
                          }
                        >
                          <table className={styles.table}>
                            <thead>
                              <tr>
                                <th className={styles.th}>File Name</th>
                                <th className={styles.th}>Source</th>
                              </tr>
                            </thead>
                            <tbody>
                              {formData.files.map(
                                (file: any, index: number) => (
                                  <tr
                                    key={file.name}
                                    className={
                                      index % 2 === 0
                                        ? styles.trOdd
                                        : styles.trEven
                                    }
                                  >
                                    <td
                                      key={`${file.name}`}
                                      className={styles.td}
                                    >
                                      {file.name}
                                    </td>
                                    <td
                                      key={`${file.name}`}
                                      className={styles.td}
                                    >
                                      <input
                                        type="text"
                                        className={styles.inputField}
                                        value={
                                          formData.source?.[file.name] || ""
                                        }
                                        onChange={(e) =>
                                          handleSourceChange(e, file.name)
                                        }
                                        placeholder="Source (optional)"
                                      />
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : formData.files.length === 1 ? (
                        <div
                          className={
                            appStateContext?.state.isSmallScreen
                              ? `form-group ${styles.formRowSmallScreen}`
                              : `form-group ${styles.formRow}`
                          }
                        >
                          <label className="control-label col-lg-1">
                            Source
                          </label>
                          <div className="col-lg-11">
                            <input
                              type="text"
                              className={styles.inputField}
                              value={
                                formData.source?.[formData.files?.[0]?.name] ||
                                ""
                              }
                              onChange={(e) =>
                                handleSourceChange(
                                  e,
                                  formData.files?.[0]?.name || ""
                                )
                              }
                              placeholder="Source (optional)"
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      ))}
                  </>
                )}
                <div className={styles.buttonGroup}>
                  <label className="control-label col-lg-1"></label>
                  <div className="col-lg-11">
                    <Button
                      type="submit"
                      className="bgColorSecondary colorDefault"
                    >
                      Upload
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </Stack>
          <Stack>
            <table className={styles.table} id={styles.docTable}>
              <thead>
                <tr>
                  <th className={styles.th}>Document Name</th>
                  <th className={styles.th}>Date</th>
                  <th className={styles.th}>Status</th>
                  <th className={styles.th}>Source</th>
                </tr>
              </thead>
              <tbody>
                {!fetching && tableData.length > 0 ? (
                  tableData.map((item: any, index: number) => (
                    <tr
                      key={item.id}
                      className={index % 2 === 0 ? styles.trOdd : styles.trEven}
                    >
                      <td key={`${item.documentname}`} className={styles.td}>
                        {item.documentname}
                      </td>
                      <td key={`${item.uploaddatetime}`} className={styles.td}>
                        {item.uploaddatetime}
                      </td>
                      <td key={`${item.status}`} className={styles.td}>
                        {fileStatus[item.status_code]}
                      </td>
                      <td key={`${item.url}`} className={styles.td}>
                        {item.url}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={4}
                      className={`${styles.td} ${styles.textAlign}`}
                    >
                      {fetching
                        ? `Fetching details. Please wait.`
                        : `No data available`}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Stack>
        </Stack.Item>
      </Stack>
      {showModal && (
        <Modal
          show={showModal}
          onHide={() => {
            setShowModal(false);
            fetchData();
          }}
          centered
        >
          <Modal.Body>{modalMsg}</Modal.Body>
          <Modal.Footer>
            <Button
              className={styles.popBtn}
              variant="secondary"
              onClick={() => {
                setShowModal(false);
                fetchData();
              }}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {showModalConfirmUpload && (
        <Modal
          show={showModalConfirmUpload}
          onHide={() => {
            setShowModalConfirmUpload(false);
            uploadAPI();
          }}
          centered
        >
          <Modal.Body>
            <div> {upload.uploadWarning}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={styles.popBtn}
              variant="secondary"
              onClick={() => {
                setShowModalConfirmUpload(false);
                uploadAPI();
              }}
            >
              Yes
            </Button>
            <Button
              className={styles.noBtn}
              variant="error"
              onClick={() => {
                setShowModalConfirmUpload(false);
              }}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {showLoader && (
        <Modal className="apiLoader" show={showLoader} centered>
          <Modal.Body>
            <Loader></Loader>
          </Modal.Body>
        </Modal>
      )}
      {showInvalidFileModal && (
        <Modal
          show={showInvalidFileModal}
          onHide={() => {
            setShowInvalidFileModal(false);
            fetchData();
          }}
          centered
        >
          {invalidFilesList.current.length > 0 ? (
            <Modal.Body>
              <div>
                {validFilesList.current.length > 0
                  ? upload.uploadSuccess
                  : upload.uploadInvalidFile}
              </div>
              {invalidFilesList.current.length > 0 && (
                <div>
                  <label>{upload.listInvalidFile}</label>
                  <ul>
                    {invalidFilesList.current.map((item) => {
                      return <li key={item}>{item}</li>;
                    })}
                  </ul>
                </div>
              )}
            </Modal.Body>
          ) : (
            <Modal.Body>{upload.uploadSuccess}</Modal.Body>
          )}
          <Modal.Footer>
            <Button
              className={styles.popBtn}
              variant="secondary"
              onClick={() => {
                invalidFilesList.current = [];
                validFilesList.current = [];
                setShowInvalidFileModal(false);
                fetchData();
              }}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default Upload;
