import React, { createContext, useReducer, ReactNode, useEffect } from "react";
import { appStateReducer } from "./AppReducer";
import {
  Conversation,
  ChatHistoryLoadingState,
  CosmosDBHealth,
  historyList,
  historyEnsure,
  CosmosDBStatus,
  frontendSettings,
  FrontendSettings,
  Feedback,
  getSharedChat,
} from "../../api";
import { getCookie } from "../../components/CarbonAIChat/common/Common";

export interface AppState {
  isChatHistoryOpen: boolean;
  isShareOpen: boolean;
  chatHistoryLoadingState: ChatHistoryLoadingState;
  isCosmosDBAvailable: CosmosDBHealth;
  chatHistory: Conversation[] | null;
  filteredChatHistory: Conversation[] | null;
  currentChat: Conversation | null;
  frontendSettings: FrontendSettings | null;
  feedbackState: {
    [answerId: string]:
      | Feedback.Neutral
      | Feedback.Positive
      | Feedback.Negative;
  };
  isAuthenticated: boolean;
  authMode: string;
  isSmallScreen: boolean;
  activeStack: string;
  extractedId: string;
  editedChatArray: Array<string>;
  activeWindow: string;
}

export type Action =
  | { type: "TOGGLE_CHAT_HISTORY" }
  | { type: "SET_COSMOSDB_STATUS"; payload: CosmosDBHealth }
  | {
      type: "UPDATE_CHAT_HISTORY_LOADING_STATE";
      payload: ChatHistoryLoadingState;
    }
  | { type: "UPDATE_CURRENT_CHAT"; payload: Conversation | null }
  | { type: "UPDATE_FILTERED_CHAT_HISTORY"; payload: Conversation[] | null }
  | { type: "UPDATE_CHAT_HISTORY"; payload: Conversation } // API Call
  | { type: "UPDATE_CHAT_TITLE"; payload: Conversation } // API Call
  | { type: "DELETE_CHAT_ENTRY"; payload: string } // API Call
  | { type: "DELETE_CHAT_HISTORY" } // API Call
  | { type: "DELETE_CURRENT_CHAT_MESSAGES"; payload: string } // API Call
  | { type: "FETCH_CHAT_HISTORY"; payload: Conversation[] | null } // API Call
  | { type: "FETCH_FRONTEND_SETTINGS"; payload: FrontendSettings | null } // API Call
  | {
      type: "SET_FEEDBACK_STATE";
      payload: {
        answerId: string;
        feedback: Feedback.Positive | Feedback.Negative | Feedback.Neutral;
      };
    }
  | { type: "GET_FEEDBACK_STATE"; payload: string }
  | { type: "SET_IS_AUTHENTICATED"; payload: boolean }
  | { type: "GET_IS_AUTHENTICATED"; payload: boolean }
  | { type: "SET_AUTH_MODE"; payload: string }
  | { type: "SET_IS_SMALL_SCREEN"; payload: boolean }
  | { type: "SET_ACTIVE_STACK"; payload: string }
  | { type: "SET_EDIT_CHAT"; payload: Array<string> }
  | { type: "TOGGLE_SHARE" }
  | { type: "SET_ACTIVE_WINDOW"; payload: string }
  | { type: "EXTRACTED_ID"; payload: string };

const initialState: AppState = {
  isChatHistoryOpen: false,
  isShareOpen: false,
  chatHistoryLoadingState: ChatHistoryLoadingState.Loading,
  chatHistory: null,
  filteredChatHistory: null,
  currentChat: null,
  isCosmosDBAvailable: {
    cosmosDB: false,
    status: CosmosDBStatus.NotConfigured,
  },
  frontendSettings: null,
  feedbackState: {},
  isAuthenticated: false,
  authMode: "",
  extractedId: "",
  isSmallScreen: false,
  activeStack: "none",
  editedChatArray: [],
  activeWindow: "chat",
};

export const AppStateContext = createContext<
  | {
      state: AppState;
      dispatch: React.Dispatch<Action>;
    }
  | undefined
>(undefined);

type AppStateProviderProps = {
  children: ReactNode;
};

export const AppStateProvider: React.FC<AppStateProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(appStateReducer, initialState);

  useEffect(() => {
    // Check for cosmosdb config and fetch initial data here
    if (state.isAuthenticated) {
      const processingDetails = () => {
        dispatch({
          type: "UPDATE_CHAT_HISTORY_LOADING_STATE",
          payload: ChatHistoryLoadingState.Loading,
        });
        historyEnsure()
          .then((response) => {
            if (response?.cosmosDB) {
              fetchChatHistory()
                .then((res) => {
                  if (res) {
                    dispatch({
                      type: "UPDATE_CHAT_HISTORY_LOADING_STATE",
                      payload: ChatHistoryLoadingState.Success,
                    });
                    dispatch({
                      type: "SET_COSMOSDB_STATUS",
                      payload: response,
                    });
                  } else {
                    dispatch({
                      type: "UPDATE_CHAT_HISTORY_LOADING_STATE",
                      payload: ChatHistoryLoadingState.Fail,
                    });
                    dispatch({
                      type: "SET_COSMOSDB_STATUS",
                      payload: {
                        cosmosDB: false,
                        status: CosmosDBStatus.NotWorking,
                      },
                    });
                  }
                })
                .catch((err) => {
                  dispatch({
                    type: "UPDATE_CHAT_HISTORY_LOADING_STATE",
                    payload: ChatHistoryLoadingState.Fail,
                  });
                  dispatch({
                    type: "SET_COSMOSDB_STATUS",
                    payload: {
                      cosmosDB: false,
                      status: CosmosDBStatus.NotWorking,
                    },
                  });
                });
            } else {
              dispatch({
                type: "UPDATE_CHAT_HISTORY_LOADING_STATE",
                payload: ChatHistoryLoadingState.Fail,
              });
              dispatch({ type: "SET_COSMOSDB_STATUS", payload: response });
            }
          })
          .catch((err) => {
            dispatch({
              type: "UPDATE_CHAT_HISTORY_LOADING_STATE",
              payload: ChatHistoryLoadingState.Fail,
            });
            dispatch({
              type: "SET_COSMOSDB_STATUS",
              payload: {
                cosmosDB: false,
                status: CosmosDBStatus.NotConfigured,
              },
            });
          });
      };
      const fetchChatHistory = async (
        offset = 0
      ): Promise<Conversation[] | null> => {
        const result = await historyList(offset)
          .then((response) => {
            if (response) {
              dispatch({ type: "FETCH_CHAT_HISTORY", payload: response });
            } else {
              dispatch({ type: "FETCH_CHAT_HISTORY", payload: null });
            }

            return response;
          })
          .catch((err) => {
            dispatch({
              type: "UPDATE_CHAT_HISTORY_LOADING_STATE",
              payload: ChatHistoryLoadingState.Fail,
            });
            dispatch({ type: "FETCH_CHAT_HISTORY", payload: null });
            console.error("There was an issue fetching your data.");
            return null;
          });
        return result;
      };
      const fetchSharedChatConv = async () => {
        const result = await getSharedChat(state.extractedId)
          .then((response) => {
            if (response.ok) {
              processingDetails();
            }
          })
          .catch((err) => {
            console.error(
              "There was an issue while authenticating shared conversation."
            );
            return null;
          });
        return result;
      };

      const getHistoryEnsure = async () => {
        console.log(state.extractedId);
        if (
          sessionStorage.getItem("isReportingHub") === "true" &&
          state.extractedId.trim().length > 0
        ) {
          fetchSharedChatConv();
        } else {
          processingDetails();
        }
      };

      if (window.location.pathname === "/") {
        getHistoryEnsure();

        dispatch({ type: "SET_EDIT_CHAT", payload: [] });
      }
    }
  }, [state.isAuthenticated, state.extractedId]);

  useEffect(() => {
    const getFrontendSettings = async () => {
      frontendSettings()
        .then((response) => {
          dispatch({
            type: "FETCH_FRONTEND_SETTINGS",
            payload: response as FrontendSettings,
          });
        })
        .catch((err) => {
          console.error("There was an issue fetching your data.");
        });
    };
    if (window.location.pathname === "/") {
      if (sessionStorage.getItem("isReportingHub") === "true") {
        const cookieValue = getCookie("Payload");

        if (cookieValue && cookieValue.includes("conversation:")) {
          dispatch({
            type: "EXTRACTED_ID",
            payload: cookieValue.split("conversation:")[1],
          });
        }
      }
      getFrontendSettings();
    }
  }, []);
  useEffect(() => {
    const history = () => {
      historyEnsure()
        .then((response) => {
          // if (response?.cosmosDB) {
          //   dispatch({
          //     type: "SET_COSMOSDB_STATUS",
          //     payload: response,
          //   });
          //   //
          // } else {
          dispatch({ type: "SET_COSMOSDB_STATUS", payload: response });
          // }
        })
        .catch((err) => {
          dispatch({
            type: "SET_COSMOSDB_STATUS",
            payload: {
              cosmosDB: false,
              status: CosmosDBStatus.NotConfigured,
            },
          });
        });
    };
    if (window.location.pathname.startsWith("/share")) {
      history();
    }
  }, []);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1023px)");
    const handleScreenChange = (e: any) => {
      dispatch({
        type: "SET_IS_SMALL_SCREEN",
        payload: e.matches,
      });
      if (e.matches)
        dispatch({
          type: "SET_ACTIVE_STACK",
          payload: "chat",
        });
      else
        dispatch({
          type: "SET_ACTIVE_STACK",
          payload: "all",
        });
    };
    // Initial check
    handleScreenChange(mediaQuery);
    mediaQuery.addListener(handleScreenChange);

    return () => mediaQuery.removeListener(handleScreenChange);
  }, []);
  return (
    <AppStateContext.Provider value={{ state, dispatch }}>
      {children}
    </AppStateContext.Provider>
  );
};
