import { useContext } from "react";
import styles from "../../CSS/Layout.module.css";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";

import Avatar from "../../../assets/android CarbonAI.svg";
import ShareBtn from "../../../components/Share/ShareBtns/ShareBtn";
export default function Header() {
  const appStateContext = useContext(AppStateContext);
  return (
    <header
      className={
        appStateContext?.state.isSmallScreen
          ? `borderBottom bgColorDefault row ${styles.headerSmallScreen} ${styles.headerShareSmallScreen} ${styles.headerShare}`
          : `borderBottom bgColorDefault row ${styles.headerShare}`
      }
      role={"banner"}
    >
      <div
        className={
          appStateContext?.state.isSmallScreen
            ? `col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xl-6 ${styles.headersubText}`
            : `col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4 ${styles.headersubText}`
        }
      >
        <a target="_blank" href={"https://carbonai.eco"}>
          <img
            alt="logo"
            src={Avatar}
            className={`${styles.headerIcon}`}
            aria-hidden="true"
          />
        </a>
        &nbsp; &nbsp;
        <div>
          <b data-cy={"share-header-text"}>CarbonAI</b>
        </div>
      </div>
      {!appStateContext?.state.isSmallScreen && (
        <div
          className={`col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4 ${styles.headersubText} ${styles.headerCenter}`}
          data-cy={"share-header-sub-text"}
        >
          Harnessing AI To Promote Low-Carbon Future
        </div>
      )}
      <div
        className={
          appStateContext?.state.isSmallScreen
            ? ` col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xl-6${styles.headersubText} ${styles.btnSection}`
            : `col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4 ${styles.headersubText} ${styles.btnSection}`
        }
      >
        <ShareBtn />
      </div>
      <br />
      {appStateContext?.state.isSmallScreen && (
        <div
          className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12 ${styles.headersubText} ${styles.headerCenter} ${styles.btnSection}`}
        >
          Harnessing AI To Promote A Low-Carbon Future
        </div>
      )}
    </header>
  );
}
