import { UserProfile } from "../../components/UserProfile/UserProfile";
import { useContext } from "react";
import { AppStateContext } from "../../state/CarbonAIChat/AppProvider";
import styles from "./UserProfile.module.css";

const UserProfileDetails = () => {
  const appStateContext = useContext(AppStateContext);

  return (
    <main
      className={
        appStateContext?.state.isSmallScreen
          ? `${styles.containerSmallScreen} bgColorPrimary borderAll`
          : `${styles.container} bgColorPrimary borderAll`
      }
    >
      <UserProfile></UserProfile>
    </main>
  );
};

export default UserProfileDetails;
