import Header from "../header/Header";
import Chat from "../chat/Chat";
import styles from "../../CSS/Layout.module.css";
import { useContext, useEffect, useState } from "react";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";
import Logo from "../../../assets/logo.svg";
import Avatar from "../../../assets/android CarbonAI.svg";
import Footer from "../../../components/Share/ShareBtns/ShareBtn";
const Layout = () => {
  const appStateContext = useContext(AppStateContext);

  return (
    <div>
      <div className={`${styles.LayoutShareChat} bgColorPrimary`}>
        <Header />
        <div className={styles.footerText} data-cy={"share-mid-text"}>
          This is a copy of shared conversation within CarbonAI.
        </div>
        <Chat />
        <footer
          className={
            appStateContext?.state.isSmallScreen
              ? `${styles.shareChatFooterSmallScreen}`
              : `${styles.shareChatFooter}`
          }
        >
          <span>
            <a target="_blank" href={"https://carbonai.eco"}>
              <img
                alt="logo"
                src={Avatar}
                className={`${styles.headerIcon}`}
                aria-hidden="true"
              />
            </a>
            <span>
              <b>CarbonAI</b>
            </span>
          </span>
          <span className={styles.footerText} data-cy={"share-footer-text"}>
            CarbonAI is pretty smart, but like all of us, it can make mistakes.
            It's always a good idea to double-check important information!
            <br />
            |&nbsp;
            <a
              target="_blank"
              href={
                "https://starcb.com/wp-content/uploads/2024/06/SCB-Group-Privacy-Notice-Clients-and-Suppliers-2024.pdf"
              }
            >
              Privacy Policy
            </a>
            &nbsp;|
          </span>
          <span>
            <a target="_blank" href={"https://starcb.com"}>
              <img
                alt="logo"
                src={Logo}
                className={`${styles.headerIcon}`}
                aria-hidden="true"
              />
            </a>
          </span>
        </footer>
      </div>
      {/* <footer className={styles.shareFooter}>
        <Footer />
      </footer> */}
    </div>
  );
};

export default Layout;
