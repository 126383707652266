import { Question, getPreDefinedQuestions } from "../../../api";
import { useEffect, useState } from "react";
import styles from "./Chat.module.css";

export const PreDefinedQuestions = ({
  onCardClick,
}: {
  onCardClick: (prompt: string) => void;
}) => {
  /**
   * Represents a component that displays pre-defined questions.
   */
  const [questions, setQuestions] = useState<Question[]>([]);

  /**
   * Fetches pre-defined questions from the server and sets the state with the fetched questions.
   */
  useEffect(() => {
    const fetchData = async () => {
      getPreDefinedQuestions().then((questions) => {
        setQuestions(
          questions instanceof Array
            ? questions.map((q) => new Question(q.title, q.prompt, q.summary))
            : []
        );
      });
    };
    fetchData();
  }, []);

  return (
    <div className={styles.widthtop100}>
      <div className={styles.pusher}></div>
      <div className={styles.width100}>
        <div className="d-flex">
          <div className={styles.askQuestion}>
            <p data-cy={"no-chat-check"}>You can ask me things such as...</p>
          </div>
        </div>
        <div className="d-flex">
          <div className={styles.questionList}>
            {questions.map((q, index) => {
              return (
                <div
                  className={styles.questionCard}
                  key={index}
                  onClick={() => onCardClick(q.prompt)}
                >
                  <div>
                    <span className={styles.questiontitle}>{q.title}</span>
                  </div>
                  <div className={styles.questionsummary}>{q.summary}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
