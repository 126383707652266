import { Stack } from "@fluentui/react";
import styles from "./Chat.module.css";

import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";
import { useContext, useState } from "react";
import { UTCToLocaleDateTime } from "../../../components/CarbonAIChat/common/Common";
import { ShareBtn } from "../../../components/CarbonAIChat/common/Button";
interface Props {
  display: {
    isLoading: boolean;
  };
}
const ChatTitle = ({ display }: Props) => {
  const appStateContext = useContext(AppStateContext);
  return (
    <Stack className={styles.chatTitleText}>
      <Stack.Item>
        <div
          style={{ padding: "0px 10px" }}
          className={styles.title}
          data-cy={"chat-title"}
        >
          {appStateContext?.state.currentChat &&
          appStateContext?.state.currentChat?.title
            ? appStateContext?.state.currentChat?.title.length > 50
              ? appStateContext?.state.currentChat?.title.slice(0, 47) + "..."
              : appStateContext?.state.currentChat?.title
            : "New Conversation"}
        </div>
        {appStateContext?.state.currentChat && (
          <div style={{ padding: "0px 10px" }} className={styles.chatDate}>
            {UTCToLocaleDateTime(appStateContext?.state.currentChat?.date).date}
          </div>
        )}
      </Stack.Item>
      {appStateContext?.state.currentChat &&
        appStateContext?.state.currentChat?.title &&
        display.isLoading === false && (
          <Stack.Item className={styles.shareBtn}>
            <button
              data-cy={"share-chat"}
              title="Share Chat"
              className="btn backIcon"
              onClick={() => {
                appStateContext?.dispatch({ type: "TOGGLE_SHARE" });
              }}
            >
              <ShareBtn />
            </button>
          </Stack.Item>
        )}
    </Stack>
  );
};

export default ChatTitle;
