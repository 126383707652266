import React, { useState, CSSProperties } from "react";
import { Icon, IIconStyles } from "@fluentui/react/lib/Icon";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import back from "../../../assets/back.svg";
// Initialize Fluent UI icons
initializeIcons();

// Styles for the Stop icon
const stopIconStyles: IIconStyles = {
  root: {
    fontSize: 20, // Adjust the size as needed
    color: "var(--secondary)", // Icon color
    display: "inline-block",
  },
};

export const CitationLink = () => (
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="blue"
      className="bi bi-box-arrow-up-right"
      viewBox="0 0 20 20"
    >
      <path
        fill-rule="evenodd"
        d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
      />
      <path
        fill-rule="evenodd"
        d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"
      />
    </svg>
  </span>
);
export const SideBarOpen = () => (
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="var(--secondary)"
      className="bi bi-layout-sidebar-inset-reverse"
      viewBox="0 0 16 16"
    >
      <path d="M2 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zm12-1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2z" />
      <path d="M13 4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1z" />
    </svg>
  </span>
);
export const ShareBtn = () => (
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="var(--secondary)"
      className="bi bi-share-fill"
      viewBox="0 0 16 16"
    >
      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5" />
    </svg>
  </span>
);
export const SideBarClose = () => (
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="var(--secondary)"
      className="bi bi-layout-sidebar-inset"
      viewBox="0 0 16 16"
    >
      <path d="M14 2a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zM2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2z" />
      <path d="M3 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1z" />
    </svg>
  </span>
);
export const SendIcons = () => (
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="var(--secondary)"
      className="bi bi-send-fill sendEnable"
      viewBox="0 0 20 20"
    >
      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
    </svg>
    {/* <Icon iconName="Send" styles={sendIconStyles} /> */}
  </span>
);
export const SendIconsDisabled = () => (
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="var(--secondary)"
      className="bi bi-send-fill sendDisable"
      viewBox="0 0 20 20"
    >
      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
    </svg>
  </span>
);
export const StopIcons = () => (
  <div>
    <Icon iconName="StopSolid" styles={stopIconStyles} />
  </div>
);
interface Props {
  color: string;
}
export const DoneIcon = () => {
  return (
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="var(--default)"
        className="bi bi-check-lg"
        viewBox="0 0 16 16"
      >
        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
      </svg>
    </span>
  );
};
export const CancelIcon = () => {
  return (
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="var(--error)"
        className="bi bi-x-lg"
        viewBox="0 0 16 16"
      >
        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
      </svg>
    </span>
  );
};
export const EditIcon = () => (
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="var(--default)"
      viewBox="0 0 16 16"
    >
      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
      <path
        fill-rule="evenodd"
        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
      />
    </svg>
  </span>
);

interface RegenerateProps {
  color: string;
}
interface Props {
  color: string;
  answerText: string;
}

export const CopyIcon = ({ color, answerText }: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async (textToCopy: string) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      console.log("Copied to clipboard:", textToCopy);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Hide the success message after 2 seconds
    } catch (err) {
      console.error("Failed to copy to clipboard:", err);
      copyToClipboardFallback(textToCopy);
      setIsCopied(true);
    }
  };

  const copyToClipboardFallback = (text: string) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      const successful = document.execCommand("copy");
      const msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }
    document.body.removeChild(textarea);
  };

  const containerStyle: CSSProperties = {
    position: "relative", // Establishes a positioning context for the absolute elements inside
    display: "inline-block", // Keeps the icon inline with text
  };

  const messageStyle: CSSProperties = {
    position: "absolute", // Position the message absolutely
    left: "0", // Align the left edge of the message with the left edge of the icon
    top: "100%", // Position the message below the icon
    backgroundColor: "green", // Add a background color for visibility
    color: "white", // Text color
    padding: "2px 6px", // Some padding around the text
    borderRadius: "4px", // Rounded corners for the tooltip box
    marginTop: "4px", // Add space between the icon and the message
    whiteSpace: "nowrap", // Prevent the message from wrapping
    zIndex: "10", // Ensure the tooltip is above other content
  };

  return (
    <div style={containerStyle} onClick={() => copyToClipboard(answerText)}>
      <span
        style={{
          cursor: "pointer",
          display: "inline-block",
          paddingLeft: "2px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="21"
          fill={color === "secondary" ? "var(--secondary)" : "var(--sub)"}
          className="bi bi-copy"
          viewBox="0 0 20 20"
        >
          <title>Copy</title>
          <path
            fill-rule="evenodd"
            d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
          />
        </svg>
      </span>
      {isCopied && <div style={messageStyle}>Copied!</div>}
    </div>
  );
};

export const ReGenIcon = ({ color }: RegenerateProps) => (
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill={color === "secondary" ? "var(--secondary)" : "var(--sub)"}
      className="bi bi-arrow-clockwise"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
      />
      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
    </svg>
  </span>
);
export const BackIcon = () => (
  <span>
    <img src={back} alt="backBtn" className="backBtn" />
  </span>
);

export const ProfileIcon = () => (
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="var(--secondary)"
      className="bi bi-person-circle"
      viewBox="0 0 16 16"
    >
      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
      <path
        fill-rule="evenodd"
        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
      />
    </svg>
  </span>
);

export const ChatIcon = () => (
  <span>
    <svg
      width="39"
      height="36"
      viewBox="0 0 39 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.3494 30.7787C28.3476 30.1407 28.9449 28.5041 28.252 30.5417C28.3115 30.6027 28.3115 30.6027 28.3688 30.6631C31.2011 30.4692 32.6749 30.1791 33.2887 27.1627C34.014 23.5978 31.0017 22.4114 30.8801 21.8479C30.843 21.6768 31.0833 20.2052 30.7995 18.3539C30.1368 14.0426 27.2267 11.7317 23.4625 9.98116C23.8459 9.77102 26.6656 8.60665 26.8455 8.643C26.9695 8.66675 27.6905 9.8768 29.0827 9.1216C29.2363 8.93759 29.489 8.773 29.5984 8.54159C29.6324 8.46775 29.6472 8.35081 29.6731 8.26295C29.7302 8.06731 29.7341 7.99963 29.7375 7.80142C29.7602 5.82362 26.4352 5.49444 26.5098 8.22362C25.8612 8.22049 22.7087 9.68914 22.4943 9.6933C22.1305 9.69773 19.4155 8.36585 15.2938 9.08179C9.14917 10.1469 8.02481 15.1834 7.44594 15.6896C7.05929 16.0269 3.93636 15.8372 2.8822 18.418C1.5992 21.5608 2.66286 23.0049 5.48104 24.4734C5.63719 24.0057 5.63719 24.0057 5.79054 23.5395C5.95169 25.9103 7.59341 27.0281 8.46367 27.6547C9.48865 28.3939 10.8717 29.2741 12.0306 29.7079C16.1454 31.2561 18.6877 31.622 22.07 31.9144C23.2734 32.0141 25.8393 31.744 27.3494 30.7787Z"
        fill="#171616"
      />
      <path
        d="M26.0001 28.8883C25.9462 28.9568 25.5407 29.2582 25.6135 29.3227C26.406 29.5493 26.406 29.5493 27.198 29.7781C25.7431 30.6611 23.9562 30.7132 22.2951 30.6498C22.714 29.0863 22.714 29.0862 23.1336 27.5205C25.1637 27.9673 26.9948 24.3622 23.5839 24.9462C23.4999 24.9237 23.4999 24.9237 23.4159 24.9012C23.411 24.8928 23.2901 24.8841 23.3108 24.8422C23.3194 24.8279 23.5363 24.7083 23.6017 24.65C23.8813 24.4025 24.2934 24.174 24.4784 23.8372C25.0411 22.8076 24.8823 21.0561 23.5981 20.6386C24.4018 17.8603 24.4018 17.8603 25.2077 15.0826C26.5021 15.9841 27.6981 17.2526 28.0982 18.8199C28.7269 21.2946 27.567 26.9237 26.0001 28.8883Z"
        fill="#FAB365"
      />
      <path
        d="M26.3215 15.6228C23.177 12.7537 15.4157 10.5816 11.8445 13.5688C9.75188 15.3191 7.83844 21.2748 7.77596 24.0644C7.49211 23.903 6.43441 23.5248 6.36492 23.2976C6.2997 23.081 6.66096 20.919 6.73985 20.5538C6.77966 20.5645 6.83301 20.5953 6.86034 20.5553C7.09037 20.2187 7.45299 16.7866 10.4194 13.3055C12.3519 11.0383 13.5892 11.0735 14.9784 10.3649C16.7844 10.0998 18.6641 10.082 20.4621 10.4572C20.4562 10.5148 20.3817 10.6513 20.431 10.6882C20.7472 10.9412 25.9682 12.1292 26.3215 15.6228Z"
        fill="#B0CCCF"
      />
      <path
        d="M22.567 11.0851C27.6024 12.9037 29.8863 16.2865 29.8674 21.6026C29.779 24.2502 29.2868 27.0693 27.8305 29.3195C27.2879 29.2215 26.7564 29.091 26.2234 28.9481C27.6188 27.1817 28.5007 22.6254 28.4507 20.4147C28.408 18.5925 27.6797 16.8614 26.3215 15.6228C25.9682 12.1292 20.7473 10.9412 20.4283 10.6898C20.3812 10.6535 20.4534 10.5164 20.4594 10.4588C21.1727 10.6096 21.882 10.8376 22.567 11.0851Z"
        fill="#208291"
      />
      <path
        d="M26.3215 15.6228C27.6797 16.8614 28.4086 18.5902 28.4507 20.4148C28.5007 22.6254 27.6188 27.1817 26.2234 28.9482C26.1482 28.928 26.0753 28.9085 26.0001 28.8883C27.5698 26.9221 28.7297 21.293 28.0976 18.8222C27.6981 17.2527 26.5015 15.9863 25.2071 15.0848C22.0294 12.8705 16.4625 11.2485 12.8236 13.1438C9.69391 14.775 8.41954 20.9375 7.92427 24.1658C7.87314 24.1355 7.84147 24.1033 7.77102 24.0655C7.83291 21.278 9.74693 15.3201 11.8395 13.5699C15.4158 10.5816 23.1742 12.7553 26.3215 15.6228Z"
        fill="#D7E5E7"
      />
      <path
        d="M15.1477 10.1756C15.2064 10.2838 15.0871 10.3134 14.975 10.3687C13.5836 11.0767 12.3463 11.0415 10.4161 13.3093C7.44964 16.7904 7.0876 20.2203 6.85698 20.5591C6.83024 20.597 6.7763 20.5683 6.73649 20.5576C7.73831 16.005 10.1861 11.1334 15.1477 10.1756Z"
        fill="#D7E5E7"
      />
      <path
        d="M29.8673 21.6026C29.8862 16.2864 27.6023 12.9037 22.5669 11.0851C22.5925 10.7862 22.7736 11.048 22.9054 10.9364C25.9506 12.1506 28.8153 14.6129 29.5985 17.9088C29.8854 19.1116 29.9069 20.3759 29.8673 21.6026Z"
        fill="#99BAC2"
      />
      <path
        d="M22.9055 10.9364C22.7736 11.0481 22.5925 10.7862 22.567 11.0851C21.8819 10.8376 21.1727 10.6096 20.4588 10.461C18.6607 10.0859 16.7811 10.1037 14.975 10.3687C15.0848 10.3128 15.2042 10.2832 15.1477 10.1756C17.7675 9.66876 20.4324 9.94906 22.9055 10.9364Z"
        fill="#D7E5E7"
      />
      <path
        d="M29.3462 8.35068C28.8997 9.46833 27.3463 9.18008 27.1884 8.13279C28.2374 7.87109 27.4112 7.434 28.8626 7.02413C29.5374 7.45145 29.1552 8.117 29.3462 8.35068Z"
        fill="#74A5AA"
      />
      <path
        d="M28.9348 6.98426C28.9231 7.0546 28.8747 7.02268 28.8647 7.02474C27.4133 7.43461 28.2396 7.8717 27.1905 8.13339C27.0507 7.2047 27.93 6.39978 28.9348 6.98426Z"
        fill="#D7E5E7"
      />
      <path
        d="M29.3461 8.35067C29.1528 8.11639 29.535 7.45084 28.8624 7.02412C28.8731 7.01985 28.9208 7.05398 28.9325 6.98364C29.3803 7.24109 29.5379 7.87351 29.3461 8.35067Z"
        fill="#ADDAE9"
      />
      <path
        d="M23.5997 20.6414C24.8816 21.0584 25.0404 22.8098 24.48 23.8401C24.2949 24.1768 23.8829 24.4054 23.6033 24.6528C21.4195 25.3191 19.7725 22.5668 21.6364 21.0421C22.1792 20.5997 22.9324 20.4247 23.5997 20.6414Z"
        fill="#171616"
      />
      <path
        d="M25.207 15.0848C24.4033 17.8631 24.4033 17.8631 23.5974 20.6408C22.9302 20.4241 22.177 20.5991 21.6336 21.0437C19.768 22.5657 21.4167 25.3208 23.6004 24.6544C23.5356 24.7105 23.3187 24.8302 23.3095 24.8467C23.2866 24.8879 23.4081 24.8944 23.4146 24.9056C23.4195 24.9141 23.3924 24.9708 23.3844 25.0184C21.326 25.5264 21.9349 27.261 23.1323 27.525C22.7134 29.0885 22.7134 29.0885 22.2938 30.6542C17.9893 30.4868 10.8776 28.5884 7.79096 25.4503C6.20588 23.8381 6.95127 24.2938 8.51754 24.7467C8.87992 24.73 8.0275 24.2314 7.9236 24.168C8.41887 20.9397 9.69323 14.7772 12.8229 13.146C16.4619 11.2507 22.0309 12.8733 25.207 15.0848Z"
        fill="#FDD9B2"
      />
      <path
        d="M14.4088 18.1787C16.9761 18.6817 16.2734 22.7551 13.7224 22.3062C10.9001 21.8084 12.1183 17.7309 14.4088 18.1787Z"
        fill="#171616"
      />
      <path
        d="M11.2723 21.171C12.3654 21.3643 13.9489 23.5663 12.4282 24.2349C10.2741 25.1794 9.04309 20.7775 11.2723 21.171Z"
        fill="#FF664A"
      />
      <path
        d="M19.8184 24.3592C19.9521 24.4709 19.9521 24.4709 20.0885 24.5809C19.1256 27.0866 14.854 25.9468 15.3405 23.3087C15.5043 23.3075 15.5043 23.3075 15.6704 23.307C15.9508 25.4893 18.4823 26.17 19.8184 24.3592Z"
        fill="#222121"
      />
      <path
        d="M23.5258 24.9331C23.5036 24.9982 23.4385 25.0021 23.3828 25.0156C23.3902 24.9702 23.4179 24.9113 23.413 24.9028C23.4705 24.9183 23.4705 24.9183 23.5258 24.9331Z"
        fill="#F99B5D"
      />
      <path
        d="M23.133 27.5228C21.9356 27.2588 21.3289 25.5248 23.385 25.0162C23.4408 25.0027 23.5053 25.001 23.528 24.9337C23.5568 24.9414 23.5568 24.9414 23.5833 24.9485C26.9948 24.3622 25.1608 27.9689 23.133 27.5228Z"
        fill="#FF664A"
      />
      <path
        d="M32.386 25.392C32.3748 26.1948 31.7669 27.8618 31.7581 28.1865C31.7557 28.2309 31.8234 28.2348 31.8632 28.2455C31.8521 28.2781 31.932 28.546 31.6361 28.872C31.313 29.2287 29.0858 29.8976 29.0434 29.6137C29.7664 27.4727 30.3806 25.2955 30.7391 23.064C31.2287 23.4748 32.2813 24.8187 32.386 25.392Z"
        fill="#397F86"
      />
      <path
        d="M31.861 28.2449C31.8234 28.2348 31.7557 28.2309 31.7559 28.1859C31.7653 27.8589 32.3725 26.1942 32.3838 25.3913C32.5099 26.0793 32.0871 27.5423 31.861 28.2449Z"
        fill="#B0CCCF"
      />
      <path
        d="M31.0357 27.1847C32.305 28.8166 31.3488 28.1835 29.8562 27.6769C30.4456 27.4319 30.4456 27.4319 31.0357 27.1847Z"
        fill="#171616"
      />
      <path
        d="M4.16881 22.3204C4.41367 22.1585 3.8398 20.9476 3.96129 20.8569C4.53205 20.9648 4.53205 20.9648 5.10059 21.0721C6.23521 21.2695 5.0876 20.7226 4.94165 20.5507C5.4959 18.9334 5.49589 18.9334 6.04792 17.3155C6.18035 17.2017 6.25801 17.0091 6.29522 16.8437C7.61772 16.3045 6.83214 16.3879 6.02061 19.4343C5.68565 20.6932 5.53156 22.029 5.28124 23.3082C4.81237 23.0854 4.49798 22.6931 4.16881 22.3204Z"
        fill="#397F86"
      />
      <path
        d="M6.05013 17.3161C5.72048 17.5999 3.7811 17.9999 3.75416 20.2942C3.67166 20.3456 3.54149 20.4245 3.51143 20.5278C3.50436 20.5188 3.4439 20.5145 3.39865 20.4976C3.62551 18.3683 4.44376 17.5968 6.29301 16.8432C6.258 17.0092 6.18035 17.2017 6.05013 17.3161Z"
        fill="#D7E5E7"
      />
      <path
        d="M3.51142 20.5278C3.69756 20.7531 4.72178 21.0085 5.1022 21.0749C4.53144 20.967 4.53144 20.967 3.96289 20.8597C3.8392 20.9498 4.41306 22.1607 4.17041 22.3233C3.5813 21.6558 3.30357 21.4273 3.40246 20.501C3.44388 20.5144 3.50435 20.5188 3.51142 20.5278Z"
        fill="#208291"
      />
      <path
        d="M4.94331 20.5536C4.52727 20.0628 5.19126 19.4159 3.75642 20.2948C3.78335 18.0005 5.72273 17.6005 6.05239 17.3167C5.49535 18.9356 5.49534 18.9356 4.94331 20.5536Z"
        fill="#B0CCCF"
      />
      <path
        d="M4.94328 20.5536C5.09084 20.7282 6.23684 21.2723 5.10222 21.0749C4.72402 21.0092 3.69758 20.7531 3.51144 20.5278C3.53929 20.4239 3.67226 20.3434 3.75417 20.2942C5.18901 19.4153 4.52724 20.0628 4.94328 20.5536Z"
        fill="#171616"
      />
    </svg>
  </span>
);
