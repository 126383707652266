import { Stack } from "@fluentui/react";
import styles from "./Chat.module.css";
import { LoadingImg } from "../../../components/CarbonAIChat/common/Common";
import { PreDefinedQuestions } from "./PreDefinedQuestions";

const NoChat = ({
  onCardClick,
  userName,
}: {
  onCardClick: (prompt: string) => void;
  userName: string;
}) => {
  return (
    <Stack className={styles.chatEmptyState}>
      <div className={styles.chatEmptyState}>
        <LoadingImg />
        <p className={styles.howcanhelp}>
          Hi {userName}, how can I help you today?
        </p>
      </div>
      <PreDefinedQuestions onCardClick={onCardClick} />
    </Stack>
  );
};

export default NoChat;
