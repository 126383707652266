import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from "remark-supersub";
import styles from "../../../pages/CarbonAIChat/chat/Chat.module.css";
import Avatar from "../../../assets/android CarbonAI.svg";
import { useMemo } from "react";
import personalStyle from "./ShareChat.module.css";
export default function ShareChat({ msg }) {
  return (
    <>
      {msg.map((answer, index) => (
        <>
          {answer.role === "user" ? (
            <div className={styles.chatMessageUser} tabIndex={0}>
              <div className={styles.userMsg}>
                <div className={styles.userMsgDiv}>
                  {/* <div className="colorSecondary marginBottom5">
                    U |&nbsp;
                    {UTCToLocaleDateTime(answer.date).date}
                    &nbsp;
                    {UTCToLocaleDateTime(answer.date).time}
                  </div> */}
                  <div className={styles.chatMessageUserMessage}>
                    <div data-cy={"user-msg"}>{answer.content}</div>
                  </div>
                </div>
                {/* <div className={`circle ${styles.userInitial}`}>U</div> */}
              </div>
            </div>
          ) : (
            <div key={`${index}`} className={styles.chatMessageGpt}>
              <div className={styles.aIMsg}>
                <div className={`circle ${styles.systemInitial}`}>
                  <img
                    alt="avtar"
                    src={Avatar}
                    className={`${styles.aiIcon}`}
                    aria-hidden="true"
                  />
                </div>
                <div className={styles.aIMsgDiv}>
                  {/* <div className="colorSecondary marginBottom5">
                    CarbonAI |&nbsp;
                    {UTCToLocaleDateTime(answer.date).date}
                    &nbsp;
                    {UTCToLocaleDateTime(answer.date).time}
                  </div> */}
                  {/* <div>{answer.content}</div> */}
                  <ShareChatAnswer answer={answer.content} />
                </div>
              </div>
            </div>
          )}
        </>
      ))}
    </>
  );
}

function ShareChatAnswer(answer) {
  function parseAnswer(answer) {
    let answerText = answer;
    const citationLinks = answerText.match(/\[(doc\d\d?\d?)]/g);
    citationLinks?.forEach((link) => {
      answerText = answerText.replaceAll(link, ``);
    });

    return answerText;
  }
  const parsedAnswer = useMemo(() => parseAnswer(answer.answer), [answer]);

  return (
    <div className={personalStyle.answerContainer} data-cy={"ai-msg"}>
      <ReactMarkdown
        linkTarget="_blank"
        remarkPlugins={[remarkGfm, supersub]}
        children={parsedAnswer}
        className={personalStyle.answerText}
      />
    </div>
  );
}
