import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route, BrowserRouter } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import "./index.css";
import Chat from "./pages/CarbonAIChat/layout/Layout";
import CWS from "./pages/CWS/layout/Layout";
import {
  AppStateContext,
  AppStateProvider,
} from "./state/CarbonAIChat/AppProvider";
import useReportingHubAuthentication from "./components/CarbonAIChat/ReportingHub/Landing";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig, loginRequest } from "./msalconfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { AuthMode } from "./api/models";
import { logUserLoginDetails } from "./api";
import { UserLoginDetails } from "./api/models";
import Share from "./pages/ShareChat/layout/Layout";

export const msalInstance = new PublicClientApplication(msalConfig);

initializeIcons();

const shouldUseRH = () => {
  // Logic to determine if RH flow should be used
  return (
    window.location.search.includes("isReportingHub=true") ||
    window.location.search.includes("?code")
  );
};

const AuthenticationBootstrap = () => {
  const [isRHEnabled, setisRHEnabled] = useState(false);
  const appStateContext = useContext(AppStateContext);
  const [isExternalUser, setisExternalUser] = useState(false);

  useReportingHubAuthentication(isRHEnabled);

  async function handleResponse(response: any) {
    if (response === null) {
      msalInstance.loginRedirect(loginRequest);
    } else {
      msalInstance.setActiveAccount(response.account);
      const activeAccount = msalInstance.getActiveAccount();
      const accounts = msalInstance.getAllAccounts();
      const user = activeAccount || accounts[0];

      if (
        user?.username &&
        user?.username !== "undefined" &&
        user?.username !== ""
      ) {
        if (!user.username.includes("@starcb.com")) {
          setisExternalUser(true);
        } else {
          if (sessionStorage.getItem("msalUserEmail") !== user.username) {
            const userLoginDetails: UserLoginDetails = {
              user_email: user.username,
              user_name: user.name || "",
              provider: "MSAL",
            };
            await logUserLoginDetails(userLoginDetails);
          }
          sessionStorage.setItem("msalUserEmail", user.username);
          if (user.name && user.name !== "undefined" && user.name !== "") {
            sessionStorage.setItem("msalUserName", user.name);
          }

          appStateContext?.dispatch({
            type: "SET_IS_AUTHENTICATED",
            payload: true,
          });
          appStateContext?.dispatch({
            type: "SET_AUTH_MODE",
            payload: AuthMode.MSAL,
          });
        }
      }
    }
  }

  useEffect(() => {
    const useRH = shouldUseRH();
    setisRHEnabled(useRH);

    const currentPath = window.location.pathname.toLowerCase();
    const isShareRoute = currentPath.startsWith("/share");

    if (useRH) {
      sessionStorage.setItem("isReportingHub", "true");
      sessionStorage.removeItem("msalUserEmail");
      sessionStorage.removeItem("msalUserName");
      appStateContext?.dispatch({
        type: "SET_AUTH_MODE",
        payload: AuthMode.ReportingHub,
      });
    } else if (!isShareRoute) {
      sessionStorage.removeItem("isReportingHub");
      sessionStorage.removeItem("RHUserName");
      sessionStorage.removeItem("RHUserEmail");
      (async () => {
        msalInstance.initialize().then(async () => {
          if (window.location.hostname !== "127.0.0.1") {
            const tokenResponse = await msalInstance.handleRedirectPromise();
            handleResponse(tokenResponse);
          } else {
            appStateContext?.dispatch({
              type: "SET_IS_AUTHENTICATED",
              payload: true,
            });
            appStateContext?.dispatch({
              type: "SET_AUTH_MODE",
              payload: AuthMode.MSAL,
            });
          }
        });
      })();
    }
  }, []);

  // Logic for handling different routes
  if (window.location.pathname.toLowerCase().startsWith("/share")) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/share/:id" element={<Share />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return isRHEnabled && appStateContext?.state.isAuthenticated ? (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Chat />} />
        <Route path="/cws" element={<CWS />} />
      </Routes>
    </BrowserRouter>
  ) : appStateContext?.state.isAuthenticated &&
    window.location.hostname != "127.0.0.1" &&
    !isExternalUser ? (
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Chat />} />
          <Route path="/cws" element={<CWS />} />
        </Routes>
      </BrowserRouter>
    </MsalProvider>
  ) : window.location.hostname === "127.0.0.1" ? (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Chat />} />
        <Route path="/cws" element={<CWS />} />
      </Routes>
    </BrowserRouter>
  ) : null;
};

export default function App() {
  useEffect(() => {
    const currentPath = window.location.pathname.toLowerCase();
    const isShareRoute = currentPath.startsWith("/share");

    // Reference to the root element
    const rootElement = document.getElementById("root");

    // Function to toggle 'scrollHide' class
    const toggleScrollHide = (element: HTMLElement | null, add: boolean) => {
      if (element) {
        if (add) {
          element.classList.remove("scrollHide");
        } else {
          element.classList.add("scrollHide");
        }
      }
    };

    // Toggle the 'scrollHide' class on both body and root based on the 'isShareRoute'
    toggleScrollHide(document.body, isShareRoute);
    toggleScrollHide(rootElement, isShareRoute);

    // Cleanup function to remove the class from both elements when component unmounts or path changes
    return () => {
      toggleScrollHide(document.body, true);
      toggleScrollHide(rootElement, true);
    };
  }, [window.location.pathname]); // Dependency on the pathname to re-run the effect when it changes
  return (
    <AppStateProvider>
      <AuthenticationBootstrap />
    </AppStateProvider>
  );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
