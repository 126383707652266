import { useContext, useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import { Loader } from "../../../components/CarbonAIChat/common/Common";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";
import styles from "../../CarbonAIChat/chat/Chat.module.css";
import personalStyles from "./chat.module.css";
import ShareChat from "../../../components/Share/ShareChat/ShareChat";
import { ChatMessage, getShareData } from "../../../api";
import { useParams } from "react-router-dom";

const Chat = () => {
  const appStateContext = useContext(AppStateContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { id } = useParams();
  const [shareData, setShareData] = useState([]);
  async function fetchShare() {
    try {
      const response = await getShareData({
        conversation_id: id,
      });
      const jsonData = await response.json();
      let data = [];
      jsonData.messages.forEach((msg: any) => {
        const message: ChatMessage = {
          id: msg.id,
          role: msg.role,
          date: msg.createdAt,
          content: msg.content,
        };
        data.push(message);
      });
      setShareData(data);
    } catch (error) {
      console.error("Error fetching data", error);
      setShareData([]);
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    if (appStateContext.state.isCosmosDBAvailable.cosmosDB) fetchShare();
  }, [appStateContext.state.isCosmosDBAvailable]);
  return (
    <main
      className={
        appStateContext?.state.isSmallScreen
          ? `${personalStyles.shareContainerSmallScreen} bgColorPrimary borderAll`
          : `${personalStyles.shareContainer} bgColorPrimary borderAll`
      }
    >
      {isLoading ? (
        <div className={personalStyles.fullHeight}>
          <Loader />
        </div>
      ) : (
        <Stack
          className={"rowAlign"}
          style={{ overflow: "hidden", display: "flex" }}
        >
          <Stack.Item
            className="bgColorPrime borderAddition"
            style={{ width: "100%", overflow: "hidden" }}
          >
            <section className={"colAlign"}>
              <Stack.Item className={`${styles.chatRootShare} `}>
                <Stack>
                  <Stack.Item
                    className={styles.chatMessageStream}
                    style={{ marginBottom: "0px" }}
                    role="log"
                  >
                    {shareData.length > 0 ? (
                      <ShareChat msg={shareData}></ShareChat>
                    ) : (
                      <div className={personalStyles.fullHeight}>
                        No Chat Available
                      </div>
                    )}
                  </Stack.Item>
                </Stack>
              </Stack.Item>
            </section>
          </Stack.Item>
        </Stack>
      )}
    </main>
  );
};

export default Chat;
