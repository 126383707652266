import React, { useState } from "react";
import Upload from "../../../components/CWS/Upload/Upload";
import styles from "./Cws.module.css";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";
import avatar from "../../../assets/android CarbonAI.svg";
import Comparison from "../../../components/CWS/FileComparison/Comparison";
const Cws: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("Upload");
  const appStateContext = React.useContext(AppStateContext);
  return (
    <div className={styles.main}>
      <ul className={styles.tabList}>
        <li
          className={`${styles.tabListItem} ${
            activeTab === "Upload" ? styles.activeTab : ""
          }`}
          onClick={() => setActiveTab("Upload")}
        >
          <img
            src={avatar}
            alt="avatar"
            className="avatar"
            style={{ width: "auto" }}
          />
          Self Upload
        </li>
        <li
          data-cy={"Comparison"}
          className={`${styles.tabListItem} ${
            activeTab === "Comparison" ? styles.activeTab : ""
          }`}
          onClick={() => setActiveTab("Comparison")}
        >
          Upload Review
        </li>
      </ul>
      <div
        className={
          appStateContext?.state.isSmallScreen
            ? styles.tabContentSmallScreen
            : styles.tabContent
        }
      >
        {activeTab === "Upload" && <Upload />}
        {activeTab === "Comparison" && <Comparison />}
        {activeTab === "Other" && <div>Content for other tab</div>}
      </div>
    </div>
  );
};

export default Cws;
