import { useParams } from "react-router-dom";
import styles from "./ShareBtn.module.css";
export default function ShareBtn() {
  const { id } = useParams();
  return (
    <>
      <button
        className={`btn ${styles.footerBtn}`}
        onClick={() => {
          window.open(
            `https://carbonai.eco/imgopn/link.html?title=CarbonChat&data=conversation:${id}`,
            "_self"
          );
        }}
      >
        Log In
      </button>
      <button
        onClick={() => {
          window.open("https://carbonai.eco?shared=join", "_self");
        }}
        className={`btn ${styles.footerBtn}`}
      >
        Join CarbonAI
      </button>
    </>
  );
}
